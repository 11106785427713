import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { AccountService } from 'src/app/services/account.service';
import { CompaniesService } from 'src/app/services/companies.service';

@Component({
  selector: 'app-user',
  standalone: true,
  imports: [CommonModule, RouterModule,ButtonModule],
  templateUrl: './user.component.html',
  styleUrl: './user.component.scss'
})
export class UserComponent implements OnInit {

  public user: any;
  public authorizations: any[];
  public selectedCompany: any;

  constructor(
    private accService: AccountService,
    private ar: ActivatedRoute,
    private companyService: CompaniesService
  ) {}

  ngOnInit(): void {
    this.ar.params.subscribe((params) => {
      this.accService.getAccount(params["id"]).subscribe({
        next: (data) => {
          console.log(data);
          this.user = data;
          this.getSelectedcompany();
        },
      });
      this.accService.getAccountAuthorizations(params["id"]).subscribe({
        next: (data) => {
          this.authorizations = [];
          this.authorizations = data;
          this.getCompaniesData();
        },
      });
      
    });
  }


  private getSelectedcompany(){
    this.companyService.getCompany(this.user.selectedCompany).subscribe({
      next: (data) => {
        this.selectedCompany = data;
      },
    });
  }

  private getCompaniesData(){
    var newAuth = [];
    this.authorizations.forEach(auth => {
      this.companyService.getCompany(auth.companyId).subscribe({
        next: (data) => {
          var auth2 = auth;
          auth2.company = data;
          newAuth.push(auth2);
        },
      });
    });
    console.log(newAuth);
    this.authorizations = newAuth;
  }
}
