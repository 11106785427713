import { CommonModule } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { AccordionModule } from "primeng/accordion";
import { MessageService } from "primeng/api";
import { ButtonModule } from "primeng/button";
import { InputSwitchModule } from "primeng/inputswitch";
import { InputTextModule } from "primeng/inputtext";
import { ToastModule } from "primeng/toast";
import { ToggleButtonModule } from "primeng/togglebutton";
import { SubscriptionService } from "src/app/services/subscriptions.service";

@Component({
  selector: "app-subscription-type",
  standalone: true,
  imports: [
    InputTextModule,
    ButtonModule,
    CommonModule,
    FormsModule,
    ToastModule,
    InputSwitchModule,
    ToggleButtonModule,
    AccordionModule,
    ToggleButtonModule,
    ButtonModule
  ],
  providers: [MessageService],
  templateUrl: "./subscription-type.component.html",
  styleUrl: "./subscription-type.component.scss",
})
export class SubscriptionTypeComponent implements OnInit {
  loaded = false;
  subscriptionType: any = {
    limits: {},
  };

  constructor(
    private messageService: MessageService,
    private ar: ActivatedRoute,
    private subscriptionService: SubscriptionService
  ) {}
  ngOnInit(): void {
    this.ar.params.subscribe((params) => {
      this.subscriptionService.getSubscriptionType(params["id"]).subscribe({
        next: (data) => {
          this.messageService.add({
            severity: "success",
            summary: "Success",
            detail: "Subscription type loaded",
          });
          this.subscriptionType = data;
          this.loaded = true;
        },
      });
    });
  }

  updateSubscriptionType() {
    this.subscriptionService
      .updateSubscriptionType(this.subscriptionType)
      .subscribe({
        next: (data) => {
          this.messageService.add({
            severity: "success",
            summary: "Success",
            detail: "Subscription type updated",
          });
        },
        error: (err) => {
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: err.error.error,
          });
        },
      });
  }

  addNewBenefit(){
    this.subscriptionType.benefits.push({
    });
  }

  removeBenefit(index: number){
    this.subscriptionType.benefits.splice(index, 1);
  }
}
