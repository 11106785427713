<p-toast />
<div *ngIf="loaded">
  <div class="card flex flex-wrap gap-3 p-fluid">
    <div class="flex-auto">
      <label for="integeronly">SubscriptionType Id</label>
      <input [(ngModel)]="subscriptionType.id" pInputText type="text" />
    </div>
    <div class="flex-auto">
      <label for="integeronly">Created At</label>
      <input [(ngModel)]="subscriptionType.createdAt" pInputText type="text" />
    </div>
    <div class="flex-auto">
      <label for="integeronly">Description</label>
      <input
        [(ngModel)]="subscriptionType.description"
        pInputText
        type="text"
      />
    </div>
    <div class="flex-auto">
      <label for="integeronly">isDefault</label>
      <p-toggleButton
        [(ngModel)]="subscriptionType.isDefault"
        onLabel="Yes"
        offLabel="No"
      />
    </div>
    <div class="flex-auto">
      <label for="integeronly">isPublic</label>
      <p-toggleButton
        [(ngModel)]="subscriptionType.isPublic"
        onLabel="Yes"
        offLabel="No"
      />
    </div>

    <div class="flex-auto">
      <label for="integeronly">Level</label>
      <input [(ngModel)]="subscriptionType.level" pInputText type="number" />
    </div>

    <div class="flex-auto">
      <label for="integeronly">Name</label>
      <input [(ngModel)]="subscriptionType.name" pInputText type="text" />
    </div>
    <div class="flex-auto">
      <label for="integeronly">Price</label>
      <input [(ngModel)]="subscriptionType.price" pInputText type="text" />
    </div>
    <div class="flex-auto">
      <label for="integeronly">Public Id</label>
      <input [(ngModel)]="subscriptionType.publicId" pInputText type="text" />
    </div>
    <div class="flex-auto">
      <label for="integeronly">User Limit</label>
      <input
        [(ngModel)]="subscriptionType.limits.userLimit"
        pInputText
        type="text"
      />
    </div>
    <div class="flex-auto">
      <label for="integeronly">Bill Limit</label>
      <input
        [(ngModel)]="subscriptionType.limits.billLimit"
        pInputText
        type="text"
      />
    </div>
    <div class="flex-auto">
      <label for="integeronly">WayBill Limit</label>
      <input
        [(ngModel)]="subscriptionType.limits.wayBillLimit"
        pInputText
        type="text"
      />
    </div>
    </div>

    <p-accordion [activeIndex]="0">
      <p-accordionTab header="Benefits">
        <div class="grid flex justify-content-center">
          <div *ngFor="let benefit of subscriptionType.benefits; let i = index" class="col-12 lg:col-4">
            <div style=" display: flex; justify-content: space-between;">
              <input pInputText type="text" style="width: 84%;" [(ngModel)]="benefit.text"/>
              <p-toggleButton
              style="    width: 15%;"
                [(ngModel)]="benefit.isAvailable"
                onLabel="On"
                offLabel="Off"
              />
            </div>
            <button style="width:100%"  severity="danger"  pButton (click)="removeBenefit(i)">Sterge</button>
          </div>

          <div class="col-12 lg:col-12">
            <button pButton (click)="addNewBenefit()">Adauga</button>
          </div>
        </div>
      </p-accordionTab>
    </p-accordion>

    <button class="p-button" (click)="updateSubscriptionType()">
      Update SubscriptionType
    </button>
  
</div>
