import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";
import { MenuItem } from "primeng/api";
import { ButtonModule } from "primeng/button";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { Menu, MenuModule } from "primeng/menu";
import { TableLazyLoadEvent, TableModule } from "primeng/table";
import { TagModule } from "primeng/tag";
import { CompaniesService } from "src/app/services/companies.service";
import { saveAs } from "file-saver";
import { PeriodSelectorDialogComponent } from "../../shared/dialogs/period-selector-dialog/period-selector-dialog.component";

@Component({
  selector: "app-facturi-primite",
  standalone: true,
  imports: [ButtonModule, MenuModule, TagModule, TableModule, CommonModule],
  providers: [DialogService],
  templateUrl: "./facturi-primite.component.html",
  styleUrl: "./facturi-primite.component.scss",
})
export class FacturiPrimiteComponent {
  bills: any[] = [];
  loading = false;
  selectedBills: any[] = [];
  selectAll = false;
  items: MenuItem[] = [];
  totalRecords: number = 0;
  ref: DynamicDialogRef | undefined;
  
  @Input() companyId: string;
  

  constructor(
    private dialogService: DialogService,
    private companieService: CompaniesService
  ) {}

  onClickDropdownMenu(bill: any, menu: Menu, event: Event) {
    this.items = [];
    //See Bill

      this.items.push({ separator: true });
      this.items.push({
        label: 'Download ZIP',
        icon: 'pi pi-folder',
        command: () => {
          this.downloadzip(bill.eFacturaBillData.id);
        },
      });
      this.items.push({
        label: 'Download eFactura PDF',
        icon: 'pi pi-file-pdf',
        command: () => {
          this.downloadEfacturaPDF(
            bill.eFacturaBillData.id,
            bill.id
          );
        },
      });


    menu.toggle(event);
  }

  private nothing(){}; // Placeholder

  downloadzip(id: string) {
    this.companieService.downloadZip(this.companyId,id).subscribe({
      next: (data) => {
        console.log(data);
        saveAs(data, `${id}}.zip`);
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  downloadEfacturaPDF(eFacturaBillId: string, billId: string) {
    this.companieService.downloadEfacturaPDF(this.companyId,billId).subscribe({
      next: (data) => {
        saveAs(data, `${eFacturaBillId}}.pdf`);
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  loadBills(event: TableLazyLoadEvent) {
    this.loading = false;
    setTimeout(() => {
      this.companieService
        .getReceivedBillsAsync(this.companyId, {
          lazyEvent: event,
        })
        .then((res) => {
          console.log(res);
          this.bills = res.values.map((x) => {
            x.style = x.style =
              "linear-gradient(-225deg,#AC32E4 0%,#7918F2 48%,#4801FF 100%)";
            x.style = "rgb(207 150 200);";

            return x;
          });
          this.totalRecords = res.count;
          this.loading = false;
        });
    }, 1000);
  }

  onSelectionChange(event: any) {}

  onSelectAllChange(event: any) {}

  downloadPeriod() {
    // Open period selector

    // Open dialog

    this.ref = this.dialogService.open(PeriodSelectorDialogComponent, {
      focusOnShow: true,
      closeOnEscape: true,
      modal: true,
      showHeader: true,
      header: "Alege Perioada",
    });
    this.ref.onClose.subscribe(async (dialogData: any) => {
      if (dialogData) {
        this.loading = true;
        console.log(dialogData);
        this.companieService
          .downloadReceivedBillsPeriod(
            this.companyId,
            dialogData[0],
            dialogData[1]
          )
          .subscribe({
            next: (data: any) => {
              console.log(data);
              saveAs(data, `raport_facturi_primite.zip`);
              this.loading = false;
            },
            error: (error: any) => {
              console.log(error);
              this.loading = false;
            },
          }); // Download
      }
    });
  }
}
