import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Account } from '../models/account.mdel';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private apiUrl = environment.baseUrl + '/api';
  private currentUserSubject: BehaviorSubject<Account | undefined> =
    new BehaviorSubject<Account | undefined>(undefined);
  public currentUser: Observable<Account | undefined>;

  constructor(private http: HttpClient, private router: Router) {
    var localStorageData = localStorage.getItem('currentAccount');
    if (localStorageData == null || localStorageData == undefined) {
      this.currentUserSubject = new BehaviorSubject<Account | undefined>(
        undefined
      );
      this.currentUser = this.currentUserSubject.asObservable();
    } else {
      this.currentUserSubject = new BehaviorSubject<any>(
        JSON.parse(localStorageData)
      );

      this.currentUser = this.currentUserSubject.asObservable();
    }
  }


  public isLoggedIn() {
    return this.currentUserValue ? true : false;
  }

  public get currentUserValue(): Account | undefined {
    return this.currentUserSubject.value;
  }

  public login(username: string, password: string){
    return this.http
      .post<Account>(this.apiUrl + '/admin/auth', { username, password }).subscribe({
        next: (data) => {
          localStorage.setItem('currentAccount', JSON.stringify(data));
          this.currentUserSubject.next(data);
          this.router.navigate(['/']);
        },
        error: (error) => {
          console.error(error);
        }
      })
  }

  public logout() {
    this.currentUserSubject.next(undefined);
    localStorage.clear();
    this.router.navigate(['/auth/login']);
  }
}
