import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { ButtonModule } from "primeng/button";
import { CalendarModule } from "primeng/calendar";
import { DynamicDialogRef } from "primeng/dynamicdialog";
import { InputNumberModule } from "primeng/inputnumber";
import { InputTextModule } from "primeng/inputtext";

@Component({
  selector: "app-add-subscriptiontype-dialog",
  standalone: true,
  imports: [
    FormsModule,
    CalendarModule,
    CommonModule,
    ButtonModule,
    InputTextModule,
    InputNumberModule,
  ],
  templateUrl: "./add-subscriptiontype-dialog.component.html",
  styleUrl: "./add-subscriptiontype-dialog.component.scss",
})
export class AddSubscriptiontypeDialogComponent {
  disabled = false;
  name: string;
  description: string;
  price: string;
  publicId: string;
  userLimit: number;
  billLimit: number;
  wayBillLimit: number;

  constructor(private dialogRef: DynamicDialogRef) {}

  add() {
    const subscriptionType = {
      name: this.name,
      description: this.description,
      price: this.price,
      publicId: this.publicId,
      limits: {
        userLimit: this.userLimit,
        billLimit: this.billLimit,
        wayBillLimit: this.wayBillLimit,
      },
    };
    this.dialogRef.close(subscriptionType);
  }
}
