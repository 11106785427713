<div class="grid">
  <div class="col-12">
    <div class="card">
      <h5>Companies</h5>
      <p-table
        [value]="companies"
        [lazy]="true"
        (onLazyLoad)="load($event)"
        dataKey="id"
        [selection]="selectedBills"
        (selectionChange)="onSelectionChange($event)"
        [selectAll]="selectAll"
        (selectAllChange)="onSelectAllChange($event)"
        [totalRecords]="totalCompanies"
        [loading]="loading"
        [paginator]="true"
        onClickDropdownMenu
        [globalFilterFields]="['name', 'cif', 'id', 'contactPerson']"
        [rows]="50"
        [showCurrentPageReport]="true"
        [tableStyle]="{ 'min-width': '50rem' }"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [rowsPerPageOptions]="[50, 100, 200]"
        styleClass="p-datatable-gridlines p-datatable-striped "
      >
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 20%">Name</th>
            <th style="width: 10%">Registered</th>
            <th style="width: 10%">Owner Id</th>
            <th style="width: 10%">Subscriptie</th>
            <th style="width: 10%">Tip Subscriptie</th>
            <th style="width: 10%">Status Subscriptie</th>
            <th style="width: 10%">Status eFactura</th>
            <th style="width: 5%">Actiuni</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-company>
          <tr>
            <td
              style="font-size: 13px"
              hideDelay="300"
              pTooltip="{{ company.name }}  ({{ company.cui }},{{
                company.regComName
              }})"
            >
              {{ company.name }} ({{ company.cui }},{{ company.regComName }})
            </td>
            <td>
              <p-tag [style]="{ background: company.registeredStyle }">{{
                company.registered
              }}</p-tag>
            </td>
            <td style="font-size: 13px">
              <p-tag>{{ company.ownerId }}</p-tag>
            </td>
            <td style="font-size: 13px">{{ company.subscriptionId }}</td>
            <td style="font-size: 13px"></td>
            <td style="font-size: 13px"></td>
            <td style="font-size: 13px">
              <p-tag [style]="{ background: company.eFacturaTagStyle }">{{
                company.efacturaAuthorized
              }}</p-tag>
            </td>
            <td>
              <p-menu
                #menu
                appendTo="body"
                [model]="items"
                [popup]="true"
              ></p-menu>
              <p-button
                [rounded]="true"
                severity="secondary"
                type="button"
                [disabled]="loading"
                styleClass="p-button-sm p-button-rounded p-button-secondary mr-2 mb-2"
                (click)="onClickDropdownMenu(company, menu, $event)"
                icon="pi pi-bars"
              ></p-button>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="paginatorleft">
          <!-- <p-button type="button" icon="pi pi-plus" styleClass="p-button-text"></p-button> -->
        </ng-template>
        <ng-template pTemplate="paginatorright">
          <!-- <p-button type="button" icon="pi pi-cloud" styleClass="p-button-text"></p-button> -->
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
