import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";
import { getClientsResponse } from "../models/api.models";
import { Subscription } from "../models/subscription.model";

@Injectable({
  providedIn: "root",
})
export class SubscriptionService {
  private apiUrl = environment.baseUrl + "/api";

  constructor(private http: HttpClient, private router: Router) {}
  
  public getCount() : Observable<number>{
    return this.http.get<number>(this.apiUrl + '/admin/count/subscriptions');
  }

  public addSubscriptionType(data: any): Observable<any> {
    return this.http.post<any>(this.apiUrl + "/admin/subscriptionTypes", data);
  }

  public getSubscriptionType(id: string): Observable<any> {
    return this.http.get<any>(this.apiUrl + `/admin/subscriptionTypes/${id}`);
  }

  public updateSubscriptionType(data: any): Observable<any> {
    return this.http.put<any>(this.apiUrl + `/admin/subscriptionTypes/${data.id}`, data);
  }

  public updateSubscription(data: any): Observable<Subscription> {
    return this.http.put<any>(this.apiUrl + `/admin/subscriptions/${data.id}`, data);
  }

  public getLimits(subId: string): Observable<any>{
    return this.http.get<any>(this.apiUrl + `/admin/subscriptions/${subId}/limits`);
  }

  public deleteSubscription(id: string): Observable<any> {
    return this.http.delete<any>(this.apiUrl + `/admin/subscriptions/${id}`);
  }

  public deleteSubscriptionType(id: string): Observable<any> {
    return this.http.delete<any>(this.apiUrl + `/admin/subscriptionTypes/${id}`);
  }

  public getSubscription(id: string): Observable<Subscription> {
    return this.http.get<any>(this.apiUrl + `/admin/subscriptions/${id}`);
  }

  public getSubscriptionLimits(id: string): Observable<any> {
    return this.http.get<any>(this.apiUrl + `/admin/subscriptions/${id}/limits`);
  }

  public getSubscriptionTypes(filters?: any): Observable<getClientsResponse> {
    var first = 0;
    var rows = 50;
    var sortOrder = 0;

    if (filters == null) {
    } else {
      first = filters.first;
      rows = filters.rows;
      sortOrder = filters.sortOrder;
    }

    return this.http.get<getClientsResponse>(
        this.apiUrl +
          `/admin/subscriptionTypes?first=${first}&rows=${rows}&sortOrder=${sortOrder}`
      )
  }

  public getSubscriptions(filters?: any): Observable<getClientsResponse> {
    console.log(filters);
    var first = 0;
    var rows = 50;
    var sortOrder = 0;

    if (filters == null) {
    } else {
      first = filters.first;
      rows = filters.rows;
      sortOrder = filters.sortOrder;
    }

    return this.http.get<getClientsResponse>(
        this.apiUrl +
          `/admin/subscriptions?first=${first}&rows=${rows}&sortOrder=${sortOrder}`
      )
  }

}
