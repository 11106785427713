<p-toast />
<div class="grid">
    <div class="col-12">
		<div class="card">
			<h5>Subscription Types</h5>
            <button class="p-button" (click)="addSubscriptionType()"> Add new type</button>
            <p-table
            [value]="subscriptions"
            [lazy]="true"
            (onLazyLoad)="loadSubscriptionsTypes($event)"
            dataKey="id"
            [selection]="selectedSubscriptions"
            (selectionChange)="onSelectionChange($event)"
            [selectAll]="selectAll"
            (selectAllChange)="onSelectAllChange($event)"
            [totalRecords]="totalRecords"
            [loading]="loading"
            [paginator]="true"
            onClickDropdownMenu
            [rows]="50"
            [showCurrentPageReport]="true"
            [tableStyle]="{ 'min-width': '50rem' }"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            [rowsPerPageOptions]="[50, 100, 200]"
            styleClass="p-datatable-gridlines p-datatable-striped "
            >
            <ng-template pTemplate="header" >
              <tr>
                <th style="width: 12%">Id</th>
                <th style="width: 10%">Subscriptie</th>
                <th style="width: 10%">Data creata</th>
                <th style="width: 10%">Pret</th>
                <th style="width: 5%">Level</th>
                <th style="width: 5%">Public</th>
                <th style="width: 10%">Actiuni</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-subscriptionType>
              <tr>
                <td style="font-size: 13px">
                  <p-tag>{{subscriptionType.id}}</p-tag>
                </td>
                <td style="font-size: 13px">
                  <p-tag>{{subscriptionType.name}}</p-tag>
                </td>
                <td style="font-size: 13px">
                 {{subscriptionType.createdAt | date}}
                </td>
                <td style="font-size: 13px">
                 {{subscriptionType.price | currency: 'RON'}}
                </td>
                <td style="font-size: 13px">
                  {{subscriptionType.level}}
                 </td>
                 <td style="font-size: 13px">
                  <p-toggleButton 
                  [(ngModel)]="subscriptionType.isPublic" 
                  onLabel="Yes" 
                  disabled
                  offLabel="No" />
                 </td>
                <td>
                    <p-menu
                    #menu
                    appendTo="body"
                    [model]="items"
                    [popup]="true"
                  ></p-menu>
                  <p-button
                    [rounded]="true"
                    severity="secondary"
                    type="button"
                    [disabled]="loading"
                    styleClass="p-button-sm p-button-rounded p-button-secondary mr-2 mb-2"
                    (click)="onClickDropdownMenu(subscriptionType, menu, $event)"
                    icon="pi pi-bars"
                  ></p-button>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="paginatorleft">
              <!-- <p-button type="button" icon="pi pi-plus" styleClass="p-button-text"></p-button> -->
            </ng-template>
            <ng-template pTemplate="paginatorright">
              <!-- <p-button type="button" icon="pi pi-cloud" styleClass="p-button-text"></p-button> -->
            </ng-template>
            </p-table>
		</div>

	</div>
</div>