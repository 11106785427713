<h3>{{user?.firstName}} {{ user?.lastName}} ( {{user?.email}} )</h3>
<h6>{{user?.id}}</h6>


<h3>Selected Company</h3>
{{selectedCompany?.name}}


<h3>Authorizations</h3>
<ul *ngFor="let auth of authorizations">
    <li>
        <div>
            {{auth?.company?.name}}
            <button size="small" pButton [routerLink]="['/companie', auth.companyId]" routerLinkActive="router-link-active"  >See Company</button>
        </div>
    </li>
</ul>
