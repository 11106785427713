import { RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";
import { NotfoundComponent } from "./demo/components/notfound/notfound.component";
import { AppLayoutComponent } from "./layout/app.layout.component";
import { SubscriptionsComponent } from "./features/subscriptions/subscriptions.component";
import { authGuard } from "./guards/auth.guard";
import { CompaniesComponent } from "./features/companies/companies.component";
import { CompanieComponent } from "./features/companie/companie.component";
import { UserComponent } from "./features/user/user.component";
import { SubscriptionComponent } from "./features/subscription/subscription.component";
import { SubscriptionTypeComponent } from "./features/subscription-type/subscription-type.component";
import { SubscriptionTypesComponent } from "./features/subscription-types/subscription-types.component";

@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        {
          path: "",
          component: AppLayoutComponent,
          children: [
            {
              path: "",
              loadChildren: () =>
                import("./demo/components/dashboard/dashboard.module").then(
                  (m) => m.DashboardModule
                ),
            },
            {
              path: "uikit",
              loadChildren: () =>
                import("./demo/components/uikit/uikit.module").then(
                  (m) => m.UIkitModule
                ),
            },
            {
              path: "utilities",
              loadChildren: () =>
                import("./demo/components/utilities/utilities.module").then(
                  (m) => m.UtilitiesModule
                ),
            },
            {
              path: "documentation",
              loadChildren: () =>
                import(
                  "./demo/components/documentation/documentation.module"
                ).then((m) => m.DocumentationModule),
            },
            {
              path: "blocks",
              loadChildren: () =>
                import("./demo/components/primeblocks/primeblocks.module").then(
                  (m) => m.PrimeBlocksModule
                ),
            },
            {
              path: "pages",
              loadChildren: () =>
                import("./demo/components/pages/pages.module").then(
                  (m) => m.PagesModule
                ),
            },
            { path: "subscriptions", component: SubscriptionsComponent },
            { path: "subscription/:id", component: SubscriptionComponent },
            { path: "subscriptionTypes", component: SubscriptionTypesComponent },
            { path: "subscriptionType/:id", component: SubscriptionTypeComponent },
            { path: "companies", component: CompaniesComponent },
            { path: "companie/:id", component: CompanieComponent },
            { path: "user/:id", component: UserComponent },
          ],
          canActivate: [authGuard],
        },
        {
          path: "auth",
          loadChildren: () =>
            import("./demo/components/auth/auth.module").then(
              (m) => m.AuthModule
            ),
        },
        {
          path: "landing",
          loadChildren: () =>
            import("./demo/components/landing/landing.module").then(
              (m) => m.LandingModule
            ),
        },
        { path: "notfound", component: NotfoundComponent },
        { path: "**", redirectTo: "/notfound" },
      ],
      {
        scrollPositionRestoration: "enabled",
        anchorScrolling: "enabled",
        onSameUrlNavigation: "reload",
      }
    ),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
