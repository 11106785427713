import { CommonModule } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MenuItem, MessageService } from "primeng/api";
import { DropdownModule } from "primeng/dropdown";
import { DynamicDialogRef } from "primeng/dynamicdialog";
import { IconFieldModule } from "primeng/iconfield";
import { InputIconModule } from "primeng/inputicon";
import { InputTextModule } from "primeng/inputtext";
import { MultiSelectModule } from "primeng/multiselect";
import { TableLazyLoadEvent, TableModule } from "primeng/table";
import { TagModule } from "primeng/tag";
import { SubscriptionService } from "src/app/services/subscriptions.service";
import { SplitButtonModule } from "primeng/splitbutton";
import { Router } from "@angular/router";
import { Menu, MenuModule } from "primeng/menu";
import { ToastModule } from "primeng/toast";

@Component({
  selector: "app-subscriptions",
  standalone: true,
  imports: [
    TableModule,
    CommonModule,
    FormsModule,
    TagModule,
    DropdownModule,
    MultiSelectModule,
    InputTextModule,
    InputIconModule,
    SplitButtonModule,
    IconFieldModule,
    ToastModule,
    MenuModule,
  ],
  providers : [MessageService],
  templateUrl: "./subscriptions.component.html",
  styleUrl: "./subscriptions.component.scss",
})
export class SubscriptionsComponent implements OnInit {
  subscriptions: any[] = [];
  loading = false;
  selectedSubscriptions: any[] = [];
  selectAll = false;
  items: MenuItem[] = [];
  totalRecords: number = 0;
  ref: DynamicDialogRef | undefined;

  constructor(
    private subscriptionService: SubscriptionService,
    private router: Router
  ) {}
  ngOnInit(): void {}

  loadSubscriptions(event: TableLazyLoadEvent) {
    this.loading = false;
    setTimeout(() => {
      this.subscriptionService.getSubscriptions(event).subscribe({
        next: (data) => {
          console.log(data);
          this.subscriptions = data.values.map((subscription: any) => {
            if (this.getEndDate(subscription) > 300)
              subscription.expireTimeStyle = "#3f81ff";

            if (this.getEndDate(subscription) < 300)
              subscription.expireTimeStyle = "#ff8300";

            if (this.getEndDate(subscription) < 250)
              subscription.expireTimeStyle = "#ff3f3f";

            return subscription;
          });
          this.totalRecords = data.count;
          this.loading = false;
        },
        error: (error) => {
          console.error(error);
          this.loading = false;
        },
      });
    }, 1000);
  }

  onSelectionChange(event: any) {}

  onSelectAllChange(event: any) {}

  onClickDropdownMenu(subscription: any,menu :Menu, event: any) {
    this.items = [];
    this.items.push({
      label: "Vezi subscriptie",
      icon: "pi pi-eye",
      command: () => {
        this.router.navigate(["/subscription", subscription.id]);
      },
      
    });
    menu.toggle(event);
  }

  getEndDate = (subscription): number => {
    var date = subscription.expiringAt;
    var diff = Math.abs(new Date(date).getTime() - new Date().getTime());
    var diffDays = Math.ceil(diff / (1000 * 3600 * 24));
    return diffDays;
  };
}
