import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-period-selector-dialog',
  standalone: true,
  imports: [FormsModule,CalendarModule,CommonModule,ButtonModule],
  templateUrl: './period-selector-dialog.component.html',
  styleUrl: './period-selector-dialog.component.scss'
})
export class PeriodSelectorDialogComponent {

  disabled = false;
  fromDate : string | undefined;
  toDate : string | undefined;

  constructor(private dialogRef: DynamicDialogRef,){

  }


  fromDateSelected(){
    
  }

  selectPeriod(){
    this.dialogRef.close(this.fromDate);
  }
}
