import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { AccountService } from 'src/app/services/account.service';

@Component({
  selector: 'app-user-select-dialog',
  standalone: true,
  imports: [    CommonModule,
    AutoCompleteModule,
    ButtonModule,
    InputTextModule,
    FormsModule,],
  templateUrl: './user-select-dialog.component.html',
  styleUrl: './user-select-dialog.component.scss'
})
export class UserSelectDialogComponent {
  public filteredUsers : any[] = [];
  public selectedUser: any;
  constructor(private dialogRef: DynamicDialogRef, private accountService : AccountService){
  }

  getName = (option: any): string => {
    return `  ${option.firstName} ${option.lastName}`;
  }

  selectAccount(){
    this.closeDialog();
  }

  filterUser(event: any) {
    const filtered: any[] = [];
    const query = event.query;
    this.accountService.getAccounts().subscribe({
      next: (users) => {
        console.log(users);
        for (let i = 0; i < users.length; i++) {
          const user = users[i];
          if (user.email.toLowerCase().indexOf(query.toLowerCase()) == 0) {
              filtered.push(user);
          }
      }
      }
    });
    console.log(this.filteredUsers);
    this.filteredUsers = filtered;
}
  closeDialog() {
    this.dialogRef.close(this.selectedUser);
  }
}
