<div class="grid">
    <div class="col-12">
		<div class="card">
			<h5>Subscriptions</h5>
            <p-table
            [value]="subscriptions"
            [lazy]="true"
            (onLazyLoad)="loadSubscriptions($event)"
            dataKey="id"
            [selection]="selectedSubscriptions"
            (selectionChange)="onSelectionChange($event)"
            [selectAll]="selectAll"
            (selectAllChange)="onSelectAllChange($event)"
            [totalRecords]="totalRecords"
            [loading]="loading"
            [paginator]="true"
            onClickDropdownMenu
            [globalFilterFields]="['name', 'cif', 'id', 'contactPerson']"
            [rows]="50"
            [showCurrentPageReport]="true"
            [tableStyle]="{ 'min-width': '50rem' }"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            [rowsPerPageOptions]="[50, 100, 200]"
            styleClass="p-datatable-gridlines p-datatable-striped "
            >
            <ng-template pTemplate="header" >
              <tr>
                <th style="width: 12%">Id</th>
                <th style="width: 10%">Subscriptie</th>
                <th style="width: 5%">Data creata</th>
                <th style="width: 5%">Data inceput</th>
                <th style="width: 5%">Data expirare</th>
                <th style="width: 13%">Companie</th>
                <th style="width: 5%">Expira in</th>
                <th style="width: 5%">Pret</th>
                <th style="width: 15%">Detalii</th>
                <th style="width: 12%">Ultima Factura</th>
                <th style="width: 5%">Actiuni</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-subscription>
              <tr>
                <td style="font-size: 13px">
                  <p-tag>{{subscription.id}}</p-tag>
                </td>
                <td style="font-size: 13px">
                  <p-tag>{{subscription.name}}</p-tag>
                </td>
                <td style="font-size: 13px">
                  {{subscription.createdAt | date}}
                </td>
                <td style="font-size: 13px">
                  {{subscription.startDate | date}}
                </td>
                <td style="font-size: 13px">
                  {{subscription.expiringAt | date}}
                </td>
                <td style="font-size: 13px">
                  {{subscription.companyId}}
                </td>
                <td style="font-size: 13px">
                  <p-tag [style]="{ background: subscription.expireTimeStyle }">{{getEndDate(subscription)}} zile</p-tag>
                </td>
                  
                <td style="font-size: 13px">
                  {{subscription.subscriptionType.price | currency: 'RON'}}
                </td>
                <td style="font-size: 13px">
                  {{subscription.subscriptionType.description}}
                </td>

                <td style="font-size: 13px">

                </td>
                <td>
                  <p-menu
                  #menu
                  appendTo="body"
                  [model]="items"
                  [popup]="true"
                ></p-menu>
                <p-button
                  [rounded]="true"
                  severity="secondary"
                  type="button"
                  [disabled]="loading"
                  styleClass="p-button-sm p-button-rounded p-button-secondary mr-2 mb-2"
                  (click)="onClickDropdownMenu(subscription, menu, $event)"
                  icon="pi pi-bars"
                ></p-button>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="paginatorleft">
              <!-- <p-button type="button" icon="pi pi-plus" styleClass="p-button-text"></p-button> -->
            </ng-template>
            <ng-template pTemplate="paginatorright">
              <!-- <p-button type="button" icon="pi pi-cloud" styleClass="p-button-text"></p-button> -->
            </ng-template>
            </p-table>
		</div>

	</div>
</div>