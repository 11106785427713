
<div *ngIf="initialLoading">
  <h4>{{ subscription?.id }}</h4>

  <label>Sub Name:</label>
  <input pInputText [(ngModel)]="subscription.name" placeholder="Username" />
  
  <label>Sub Type</label>
  <p-dropdown
    [options]="subscriptionTypes"
    [(ngModel)]="subscriptionType"
    optionLabel="name"
    (onChange)="onSubscriptionTypeChange($event)"
  >
  </p-dropdown>
  <p>CreatedAt</p>
  <input pInputText type="text" disabled [(ngModel)]="subscription.createdAt" />
  <p>StartDate</p>
  <input pInputText type="text" disabled [(ngModel)]="subscription.startDate" />
  <p>ModifiedAt</p>
  <input pInputText type="text" disabled [(ngModel)]="subscription.modifiedAt" />
  <p>ExpiringAt</p>
  <p-calendar
    appendTo="body"
    class="p-inputtext-sm"
    dateFormat="mm/dd/yy"
    dataType="string"
    [showTime]="true" 
    [touchUI]="true" 
    [(ngModel)]="expiringAt"
    [touchUI]="true"
    [showButtonBar]="true"
    (onSelect)="fromDateSelected()"
  ></p-calendar>(in {{ getEndDate(subscription) }} zile)
  
  <!-- dateFormat="yy-mm-dd'T':00:00.0000000'Z'" -->
  
  <p>Recurring:</p>
  <p-toggleButton 
  [(ngModel)]="subscription.recurring" 
  onLabel="Yes" 
  offLabel="No" />
  
  
  <h5>SubscriptionType</h5>
  id
  <p>
    {{ subscription?.subscriptionType?.id }}
  </p>
  name
  <p>
    {{ subscription?.subscriptionType?.name }}
  </p>
  description
  <p>
    {{ subscription?.subscriptionType?.description }}
  </p>
  publicId
  <p>
    {{ subscription?.subscriptionType?.publicId }}
  </p>
  userLimit
  <p>
    {{ subscription?.reachedLimits?.userLimit }} / {{ subscription?.subscriptionType?.limits.userLimit }}
  </p>
  billLimit
  <p>
    {{ subscription?.reachedLimits?.billLimit }} / {{ subscription?.subscriptionType?.limits.billLimit }}
  </p>
  wayBillLimit
  <p>
    {{ subscription?.reachedLimits?.wayBillLimit }}/ {{ subscription?.subscriptionType?.limits.wayBillLimit }}
  </p>
  
  <button class="p-button" (click)="updateSubscription()">
    Update Subscription
  </button>
  
  <p-toast />
  <p-confirmDialog />
  
  
    <p-button label="Delete Subscription" severity="danger"  (click)="confirmDeleteSubscription($event)"/>
</div>

