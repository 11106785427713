import { CommonModule } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { Router } from "@angular/router";
import { LazyLoadEvent, MenuItem, MessageService } from "primeng/api";
import { ButtonModule } from "primeng/button";
import { DropdownModule } from "primeng/dropdown";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { IconFieldModule } from "primeng/iconfield";
import { InputIconModule } from "primeng/inputicon";
import { InputTextModule } from "primeng/inputtext";
import { Menu, MenuModule } from "primeng/menu";
import { MultiSelectModule } from "primeng/multiselect";
import { SplitButtonModule } from "primeng/splitbutton";
import { TableLazyLoadEvent, TableModule } from "primeng/table";
import { TagModule } from "primeng/tag";
import { SubscriptionService } from "src/app/services/subscriptions.service";
import { AddSubscriptiontypeDialogComponent } from "../shared/dialogs/add-subscriptiontype-dialog/add-subscriptiontype-dialog.component";
import { ToastModule } from "primeng/toast";
import { RippleModule } from "primeng/ripple";
import { ToggleButtonModule } from "primeng/togglebutton";

@Component({
  selector: "app-subscription-types",
  standalone: true,
  imports: [
    TableModule,
    CommonModule,
    FormsModule,
    TagModule,
    DropdownModule,
    MultiSelectModule,
    InputTextModule,
    InputIconModule,
    SplitButtonModule,
    IconFieldModule,
    MenuModule,
    ButtonModule,
    ToastModule,
    RippleModule,
    ToggleButtonModule,
  ],
  providers: [DialogService,MessageService],
  templateUrl: "./subscription-types.component.html",
  styleUrl: "./subscription-types.component.scss",
})
export class SubscriptionTypesComponent implements OnInit {
  subscriptions: any[] = [];
  loading = false;
  selectedSubscriptions: any[] = [];
  selectAll = false;
  items: MenuItem[] = [];
  totalRecords: number = 0;
  ref: DynamicDialogRef | undefined;

  private lastTableLazyLoadEvent: TableLazyLoadEvent;

  constructor(
    private subscriptionService: SubscriptionService,
    private router: Router,
    private messageService: MessageService,
    private dialogService: DialogService
  ) {}
  ngOnInit(): void {
    
  }

  loadSubscriptionsTypes(event: TableLazyLoadEvent) {
    this.lastTableLazyLoadEvent = event;
    this.loading = false;
    setTimeout(() => {
      this.subscriptionService.getSubscriptionTypes(event).subscribe({
        next: (data) => {
          this.subscriptions = data.values;
          this.totalRecords = data.count;
        },
        error: (error) => {
          console.error(error);
          this.loading = false;
        },
      });
    }, 1000);
  }

  onSelectionChange(event: any) {}

  onSelectAllChange(event: any) {}

  onClickDropdownMenu(subscriptionType: any,menu :Menu, event: any) {
    this.items = [];
    this.items.push({
      label: "Vezi detalii",
      icon: "pi pi-eye",
      command: () => {
        this.router.navigate(["/subscriptionType", subscriptionType.id]);
      },
      
    });
    this.items.push({
      label: "Sterge",
      icon: "pi pi-trash",
      command: () => {
        this.deleteSubscriptionType(subscriptionType);
      },
      
    });
    menu.toggle(event);
  }

  deleteSubscriptionType(subscriptionType: any){
    this.subscriptionService.deleteSubscriptionType(subscriptionType.id).subscribe({
      next: (data) => {
        this.messageService.add({severity:'success', summary: 'Success', detail: 'SubscriptionType deleted'});
        this.loadSubscriptionsTypes(this.lastTableLazyLoadEvent);
      },
      error: (error) => {
        this.messageService.add({severity:'error', summary: 'Error', detail: error.error.error});
      }
    });
  }

  addSubscriptionType(){
    this.ref = this.dialogService.open(AddSubscriptiontypeDialogComponent, {
      focusOnShow: true,
      closeOnEscape: true,
      modal: true,
      showHeader: true,
      header: 'Adauga un subscriptionType',
    });
    this.ref.onClose.subscribe((type: any) => {
      this.subscriptionService.addSubscriptionType(type).subscribe({
        next: (data) => {
          this.messageService.add({severity:'success', summary: 'Success', detail: 'SubscriptionType added'});
          this.loadSubscriptionsTypes(this.lastTableLazyLoadEvent);
        },
        error: (error) => {
          this.messageService.add({severity:'error', summary: 'Error', detail: error.error.error});
        }
      });
    });
  }
}
