import { Component, Input } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { FacturiPrimiteComponent } from './facturi-primite/facturi-primite.component';
import { FacturiTrimiseComponent } from './facturi-trimise/facturi-trimise.component';
import { BadgeModule } from 'primeng/badge';
import { TabMenuModule } from 'primeng/tabmenu';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-facturi',
  standalone: true,
  imports: [FacturiPrimiteComponent,FacturiTrimiseComponent, BadgeModule,TabMenuModule, CommonModule],
  templateUrl: './facturi.component.html',
  styleUrl: './facturi.component.scss'
})
export class FacturiComponent {
  tabItems: MenuItem[] | undefined;
  activeItem: MenuItem;
  @Input() companyId: string;

  constructor(){
    this.tabItems = [
      { id: 't', label: 'Trimise', icon: 'pi pi-fw pi-home' },
      { id: 'p', label: 'Primite', icon: 'pi pi-fw pi-calendar' },
    ];

    this.activeItem = this.tabItems[0];
      
  }

  onActiveItemChange(event: MenuItem) {
    this.activeItem = event;
  }

}
