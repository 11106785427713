import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { MenuModule } from 'primeng/menu';
import { TableModule } from 'primeng/table';
import { TagModule } from 'primeng/tag';

@Component({
  selector: 'app-facturi-trimise',
  standalone: true,
  imports: [ButtonModule, MenuModule,TagModule,
    TableModule,
    CommonModule ],
  templateUrl: './facturi-trimise.component.html',
  styleUrl: './facturi-trimise.component.scss'
})
export class FacturiTrimiseComponent {
  @Input() companyId: string;
  
  bills: any[] = [];
  loading = false;
  selectedBills: any[] = [];
  selectAll = false;
  items: MenuItem[] = [];
  totalRecords: number = 0;

}
