<p-tabMenu
  [model]="tabItems"
  [activeItem]="activeItem"
  (activeItemChange)="onActiveItemChange($event)"
>
  <ng-template pTemplate="tabItem" let-tabItem>
    <a
      class="p-menuitem-link flex justify-content-between align-items-center p-3"
    >
      <div>
        <span [class]="tabItem.icon"></span>
        <span> {{ tabItem.label }}</span>
      </div>
      <div>
        <span *ngIf="tabItem.shortcut" [class]="tabItem.shortcutClass">{{
          tabItem.shortcut
        }}</span>
        <p-badge
          *ngIf="tabItem.badge"
          [value]="tabItem.badge"
          [severity]="tabItem.badgeSeverity"
        ></p-badge>
      </div>
    </a>
  </ng-template>
</p-tabMenu>

<div *ngIf="this.activeItem.id == 't'">
  <app-facturi-trimise
  ></app-facturi-trimise>
</div>

<div *ngIf="this.activeItem.id == 'p'">
  <app-facturi-primite [companyId]="companyId"
  ></app-facturi-primite>
</div>