<div id="container">
    Selectati perioada (max 2 luni)
    <p-calendar
    appendTo="body"
    class="p-inputtext-sm"
    [disabled]="disabled"
    dateFormat="dd-mm-yy"
    dataType="string"
    [style]="{ width: '100%' }"
    [(ngModel)]="fromDate"
    selectionMode="range" 
    [touchUI]="true" 
    [showButtonBar]="true"
    (onSelect)="fromDateSelected()"
    ></p-calendar>

    <button pButton (click)="selectPeriod()">Alege perioada</button>
</div>

