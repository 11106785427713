import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, lastValueFrom, Observable, of } from "rxjs";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";
import { getClientsResponse } from "../models/api.models";

@Injectable({
  providedIn: "root",
})
export class CompaniesService {
  private apiUrl = environment.baseUrl + "/api";

  constructor(private http: HttpClient, private router: Router) {}

  public getCompaniesCount(): Observable<number> {
    return this.http.get<number>(this.apiUrl + "/admin/count/companies");
  }

  public getCompaniesWithOwnerCount(): Observable<number> {
    return this.http.get<number>(
      this.apiUrl + "/admin/count/companiesWithOwner"
    );
  }

  public getCompanies(filters?: any): Observable<getClientsResponse> {
    console.log(filters);
    var first = 0;
    var rows = 50;
    var sortOrder = 0;

    if (filters == null) {
    } else {
      first = filters.first;
      rows = filters.rows;
      sortOrder = filters.sortOrder;
    }

    return this.http.get<getClientsResponse>(
        this.apiUrl +
          `/admin/companies/?first=${first}&rows=${rows}&sortOrder=${sortOrder}`
      )
  }

  public getSubscriptions(id: string): Observable<any> {
    return this.http.get<any>(this.apiUrl + `/companies/${id}/subscriptions`);
  }

  public getCompany(id: string): Observable<any> {
    return this.http.get<any>(this.apiUrl + `/admin/companies/${id}`);
  }

  public getCompanyUsers(id: string): Observable<any> {
    return this.http.get<any>(this.apiUrl + `/admin/companies/${id}/users`);
  }

  public syncBills(id: string) : Observable<any>{
    return this.http.post<any>(this.apiUrl + `/admin/companies/${id}/syncBills`, {});
  }

  public refreshEfacturaAuthorization() : Observable<any>{
    return this.http.post<any>(this.apiUrl + `/admin/eFacturaReauthroizeCompanies`, {});
  }

  public addUser(id: string, email: string) : Observable<any>{
    return this.http.post<any>(this.apiUrl + `/admin/companies/${id}/addUser`, {email:email});
  }

  public removeUser(companyId: string, userId: string) : Observable<any>{
    return this.http.post<any>(this.apiUrl + `/admin/companies/${companyId}/users/${userId}/delete`, {});
  }

  // BILLS
  public async getReceivedBillsAsync(companyId : string,
    filters?: any
  ): Promise<getClientsResponse> {
    var first = 0;
    var rows = 50;
    var sortOrder = 0;
    if (filters == null) {
    } else {
      first = filters.lazyEvent.first;
      rows = filters.lazyEvent.rows;
      sortOrder = filters.lazyEvent.sortOrder;
    }

    var data = await lastValueFrom(
      this.http.get<getClientsResponse>(
        this.apiUrl +
          `/companies/${companyId}/bills/received?first=${first}&rows=${rows}&sortOrder=${sortOrder}`
      )
    );
    return data;
  }

  downloadReceivedBillsPeriod(companyId:string, from: string, to: string) {
    const httpOptions: Object = {
      headers: new HttpHeaders({}),
      responseType: 'blob',
    };
    return this.http.post<any>(
      `${this.apiUrl}/companies/${companyId}/bills/received/download/pdf`,
      { startDate: from, endDate: to },
      httpOptions
    );
  }

  public downloadEfacturaPDF(companyId:string, billId:string){
    const httpOptions : Object = {
      headers: new HttpHeaders({

      }),
      responseType: 'blob'
    };
    return this.http.get<any>(this.apiUrl + `/companies/${companyId}/eFactura/pdf2/${billId}`,httpOptions).pipe(
      catchError(this.handleError<any>('downloadEfacturaPDF', undefined)) // then handle the error
    );
  }

  public downloadZip(companyId: string, billId : string){
    const httpOptions : Object = {
      headers: new HttpHeaders({

      }),
      responseType: 'blob'
    };
    return this.http.get<any>(this.apiUrl + `/companies/${companyId}/eFactura/zip/${billId}`,httpOptions).pipe(
      catchError(this.handleError<any>('downloadZip', undefined)) // then handle the error
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
  
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
  
      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);
  
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
