import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class AccountService {
  private apiUrl = environment.baseUrl + "/api";

  constructor(private http: HttpClient, private router: Router) {}
  
  public getAccountsCount() : Observable<number>{
    return this.http.get<number>(this.apiUrl + '/admin/count/users');
  }

  public getAccount(id:string) : Observable<any>{
    return this.http.get<any>(this.apiUrl + `/admin/users/${id}`);
  }

  public getAccountAuthorizations(id: string) : Observable<any>{
    return this.http.get<any>(this.apiUrl + `/admin/users/${id}/authorizations`);
  }

  public getAccounts() : Observable<any[]>{
    return this.http.get<any[]>(this.apiUrl + `/admin/users`);
  }

}
