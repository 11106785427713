import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ConfirmationService, MessageService } from "primeng/api";
import { ButtonModule } from "primeng/button";
import { CalendarModule } from "primeng/calendar";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { DropdownModule } from "primeng/dropdown";
import { DialogService } from "primeng/dynamicdialog";
import { InputGroupModule } from "primeng/inputgroup";
import { InputGroupAddonModule } from "primeng/inputgroupaddon";
import { InputTextModule } from "primeng/inputtext";
import { ToastModule } from "primeng/toast";
import { ToggleButtonModule } from "primeng/togglebutton";
import { Subscription } from "src/app/models/subscription.model";
import { SubscriptionService } from "src/app/services/subscriptions.service";


@Component({
  selector: "app-subscription",
  standalone: true,
  imports: [
    ButtonModule,
    InputGroupAddonModule,
    InputGroupModule,
    InputTextModule,
    FormsModule,
    CalendarModule,
    DropdownModule,
    ConfirmDialogModule,
    ToggleButtonModule,
    ToastModule,
    CommonModule,
  ],
  providers: [DialogService,ConfirmationService, MessageService],
  templateUrl: "./subscription.component.html",
  styleUrl: "./subscription.component.scss",
})
export class SubscriptionComponent {
  subscription: Subscription | undefined;
  subscriptionTypes : any[] = [];
  subscriptionType: any;
  expiringAt: any;
  initialLoading = false;

  constructor(
    private subscriptionService: SubscriptionService,
    private ar: ActivatedRoute,
    private dialogService: DialogService,
    private router: Router,
    private confirmationService: ConfirmationService, private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.ar.params.subscribe((params) => {
      this.subscriptionService.getSubscription(params["id"]).subscribe({
        next: (data) => {
          this.subscription = data;
          this.subscriptionType = data.subscriptionType;
          this.expiringAt = new Date(this.subscription.expiringAt).toLocaleDateString() + ` ${new Date(this.subscription.expiringAt).getHours()}:${new Date(this.subscription.expiringAt).getMinutes()}`;
          this.initialLoading = true;
        },
      });
      this.subscriptionService.getSubscriptionLimits(params["id"]).subscribe({
        next: (data) => {
          this.subscription.reachedLimits = data;
        },
      });
    });

    this.loadSubscriptionTypes();
  }

  onSubscriptionTypeChange(event: any){
    this.subscription.subscriptionTypeId = event.value.id;
    this.subscription.subscriptionType = event.value;
  }

  loadSubscriptionTypes() {
    this.subscriptionService.getSubscriptionTypes().subscribe({
      next: (data) => {
        this.subscriptionTypes = data.values;
      },
    });
  }

  updateSubscription(){

    // format expiringAt
    this.subscription.expiringAt = new Date(this.expiringAt).toISOString();
    
    this.subscriptionService.updateSubscription(this.subscription).subscribe({
      next: (data) => {
        this.messageService.add({ severity: 'success', summary: 'Accepted', detail: 'Subscription Updated' });
      },
    });

    console.log(this.expiringAt);
  }

  confirmDeleteSubscription(event: Event) {
    this.confirmationService.confirm({
        target: event.target as EventTarget,
        message: 'Do you want to delete this record?',
        header: 'Delete Confirmation',
        icon: 'pi pi-info-circle',
        acceptButtonStyleClass:"p-button-danger p-button-text",
        rejectButtonStyleClass:"p-button-text p-button-text",
        acceptIcon:"none",
        rejectIcon:"none",

        accept: () => {
            console.log("PL");
            this.deleteSubscription();
        },
        reject: () => {
            this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected' });
        }
    });
}

  deleteSubscription(){
    this.subscriptionService.deleteSubscription(this.subscription.id).subscribe({
      next: (data) => {
        this.router.navigate(["/subscriptions"]);
      },
    });
  }

  getEndDate = (subscription): number => {
    var date = subscription.expiringAt;
    var diff = new Date(date).getTime() - new Date().getTime();
    var diffDays = Math.ceil(diff / (1000 * 3600 * 24));
    return diffDays;
  };
  
  fromDateSelected(){
    
  }
}

