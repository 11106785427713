<div class="table-container">
    <p-table
  [value]="bills"
  [lazy]="true"
  (onLazyLoad)="loadBills($event)"
  dataKey="id"
  [selection]="selectedBills"
  (selectionChange)="onSelectionChange($event)"
  [selectAll]="selectAll"
  (selectAllChange)="onSelectAllChange($event)"
  [totalRecords]="totalRecords"
  [loading]="loading"
  [paginator]="true"
  onClickDropdownMenu
  [globalFilterFields]="['name', 'cif', 'id', 'contactPerson']"
  [rows]="10"
  [showCurrentPageReport]="true"
  [tableStyle]="{ 'min-width': '50rem' }"
  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
  [rowsPerPageOptions]="[10, 20, 50]"
  styleClass="p-datatable-gridlines p-datatable-striped "
  >
  <ng-template pTemplate="header">
    <tr>
      <th style="width: 20%">Furnizor</th>
      <th style="width: 1%">Serie</th>
      <th style="width: 10%">Data Emiterii</th>
      <th style="width: 10%">Data Scadentei</th>
      <th style="width: 10%">Total</th>
      <th style="width: 20%">Mentiuni</th>
      <th style="width: 10%">Status Plata</th>
      <th style="width: 5%">Actiuni</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-bill>
    <tr>
      <td style="font-size: 13px">
        {{ bill.seller.name }} ({{ bill.seller.cif }})
      </td>
      <td style="font-size: 13px">
        {{ bill.serie }}
      </td>
      <td style="font-size: 13px">{{ bill.issueDate | date }}</td>
      <td style="font-size: 13px">{{ bill.dueDate | date }}</td>
      <td style="font-size: 13px">
        {{ bill.calculatedTotalWithTVA | number }} {{ bill.currencyCode }}
      </td>
      <td style="font-size: 13px">{{ bill.mentions }} Desc: {{bill.eFacturaBillData?.id_Descarcare}} Inc: {{bill.eFacturaBillData?.id_Incarcare}}</td>
      <td style="font-size: 13px">
        <p-tag
        value="{{ bill.cashedStatus }}"
        ></p-tag>
      </td>
      <td>
        <p-menu
          #menu
          appendTo="body"
          [model]="items"
          [popup]="true"
        ></p-menu>
        <p-button
          [rounded]="true"
          severity="secondary"
          type="button"
          [disabled]="loading"
          (click)="onClickDropdownMenu(bill, menu, $event)"
          icon="pi pi-bars"
        ></p-button>
        <!-- <div class="card flex justify-content-center">
                    <p-splitButton label="Actiuni"  styleClass="p-button-sm p-button-rounded p-button-secondary mr-2 mb-2" (onClick)="onClickDropdownMenu(bill.id, $event)" [model]="items"></p-splitButton>
                </div> -->
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="paginatorleft">
    <!-- <p-button type="button" icon="pi pi-plus" styleClass="p-button-text"></p-button> -->
  </ng-template>
  <ng-template pTemplate="paginatorright">
    <!-- <p-button type="button" icon="pi pi-cloud" styleClass="p-button-text"></p-button> -->
  </ng-template>
  </p-table>
  
  <button [disabled]="loading" (click)="downloadPeriod()" type="button"  size="small" pButton>Descarca Facturile pe o perioada</button>
  </div>