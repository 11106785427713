import {  inject } from '@angular/core';
import {  CanActivateFn, Router,  } from '@angular/router';

import { AuthService } from '../services/auth.service';

export const authGuard: CanActivateFn =  (route, state) => {
    var loggedIn = inject(AuthService).isLoggedIn();
    var router = inject(Router);
    if (loggedIn) {
        // logged in so return true
        return true;
    }

    // not logged in so redirect to login page with the return url
    router.navigate(['./auth/login'], { queryParams: { returnUrl: state.url } });
    return false;
  };

  export const notAuthGuard: CanActivateFn =  (route, state) => {
    var loggedIn = inject(AuthService).isLoggedIn();
    var router = inject(Router);
    if (!loggedIn) {
        // logged in so return true
        return true;
    }

    // not logged in so redirect to login page with the return url
    router.navigate(['/'], { queryParams: { returnUrl: state.url } });
    return false;
  };