import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable, OnInit } from "@angular/core";
import { AuthService } from "../services/auth.service";
import { Observable, catchError, of, tap } from "rxjs";
import { MessageService } from "primeng/api";

@Injectable()
export class JwtInterceptor implements OnInit, HttpInterceptor {
  token: string;

  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe((user) => {
      if (user != undefined) {
        this.token = user.token;
      }
    });
  }

  constructor(private authenticationService: AuthService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    request.headers.append("Access-Control-Allow-Origin", "*");
    // add authorization header with jwt token if available
    const token = this.authenticationService.currentUserValue?.token;
    if (token != undefined) {
      request = request.clone({
        setHeaders: {
          "Access-Control-Allow-Origin": `*`,
          Authorization: `Bearer ${token}`,
        },
      });
    }

    return next.handle(request).pipe(
      tap()
    );
  }
}
