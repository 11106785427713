<div id="container">
  <div class="flex flex-column gap-2">
    <label  class="block font-bold mb-2" for="username">Name</label>
    <input
      pInputText
      id="name"
      aria-describedby="username-help"
      [(ngModel)]="name"
    />
    <small id="name-help"> A name for the subscription type. </small>
  </div>
  <div class="flex flex-column gap-2">
    <label  class="block font-bold mb-2" for="username">Description</label>
    <input
      pInputText
      id="description"
      aria-describedby="username-help"
      [(ngModel)]="description"
    />
    <small id="description-help">
      A description for the subscription type.
    </small>
  </div>
  <div class="flex flex-column gap-2">
    <label  class="block font-bold mb-2" for="publicId">Public Id</label>
    <input
      pInputText
      id="publicId"
      aria-describedby="publicId-help"
      [(ngModel)]="publicId"
    />
    <small id="publicid-help"> A public identificator. </small>
  </div>
  <div class="flex flex-column gap-2">
    <label class="block font-bold mb-2" for="locale-user">Pret (RON)</label>
    <p-inputNumber
      id="price"
      mode="decimal" 
      locale="en-US" 
      [minFractionDigits]="2"
      aria-describedby="price-help"
      [(ngModel)]="price"
    />
    <small id="price-help"> Price for this subscription </small>
  </div>

  <div class="flex flex-column gap-2">
    <label  class="block font-bold mb-2" for="billLimit">Bill Limit</label>
    <p-inputNumber
      id="billLimit"
      aria-describedby="billLimit-help"
      [(ngModel)]="billLimit"
    />
    <small id="billLimit-help"> Monthly bill limit. </small>
  </div>
  <div class="flex flex-column gap-2">
    <label  class="block font-bold mb-2" for="wayBillLimit">Waybill Limit</label>
    <p-inputNumber
      id="wayBillLimit"
      aria-describedby="wayBillLimit-help"
      [(ngModel)]="wayBillLimit"
    />
    <small id="wayBillLimit-help"> Monthly waybill limit. </small>
  </div>
  <div class="flex flex-column gap-2">
    <label  class="block font-bold mb-2" for="userLimit">Uer Limit</label>
    <p-inputNumber
      id="userLimit"
      aria-describedby="userLimit-help"
      [(ngModel)]="userLimit"
    />
    <small id="userLimit-help"> Monthly user limit. </small>
  </div>
  <button pButton (click)="add()">Adauga tip</button>
</div>
