<div class="auto">
    <p-autoComplete
    [(ngModel)]="selectedUser"
    [suggestions]="filteredUsers"
    (completeMethod)="filterUser($event)"
    field="email"
    [dropdown]="true"
  >
  </p-autoComplete>
  

  <button size="small" pButton (click)="selectAccount()">Adauga utilizator</button>
</div>