import { CommonModule } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { DropdownModule } from "primeng/dropdown";
import { InputTextModule } from "primeng/inputtext";
import { MultiSelectModule } from "primeng/multiselect";
import { Table, TableLazyLoadEvent, TableModule } from "primeng/table";
import { TagModule } from "primeng/tag";
import { IconFieldModule } from "primeng/iconfield";
import { InputIconModule } from "primeng/inputicon";
import { MenuItem } from "primeng/api";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { CompaniesService } from "src/app/services/companies.service";
import { ButtonModule } from "primeng/button";
import { SplitButtonModule } from "primeng/splitbutton";
import { Router } from "@angular/router";
import { Menu, MenuModule } from "primeng/menu";

@Component({
  selector: "app-companies",
  standalone: true,
  imports: [
    TableModule,
    CommonModule,
    FormsModule,
    TagModule,
    DropdownModule,
    MultiSelectModule,
    InputTextModule,
    InputIconModule,
    IconFieldModule,
    ButtonModule,
    SplitButtonModule,
    MenuModule,
  ],
  templateUrl: "./companies.component.html",
  styleUrl: "./companies.component.scss",
})
export class CompaniesComponent implements OnInit {
  companies: any[] = [];
  loading = false;
  selectedBills: any[] = [];
  selectAll = false;
  items: MenuItem[] = [];
  totalCompanies: number = 0;
  ref: DynamicDialogRef | undefined;

  constructor(
    private companyService: CompaniesService,
    private router: Router
  ) {}
  ngOnInit(): void {

  }

  load(event: TableLazyLoadEvent) {
    this.loading = false;
    setTimeout(() => {
      this.companyService.getCompanies(event).subscribe({
        next: (data) => {
          this.companies = data.values.map((company: any) => {
            if (company.efacturaAuthorized == true) {
              company.eFacturaTagStyle = "#3bbd70";
            } else {
              company.eFacturaTagStyle = "#f95e5e";
            }

            if (company.ownerId == "00000000-0000-0000-0000-000000000000") {
              company.registered = false;
              company.registeredStyle = "#f95e5e";
            } else {
              company.registered = true;
              company.registeredStyle = "#3bbd70";
            }
            return company;
          });
          this.totalCompanies = data.count;
          this.loading = false;
        },
        error: (error) => {
          console.error(error);
          this.loading = false;
        },
      });
    }, 1000);
  }

  onSelectionChange(event: any) {}

  onSelectAllChange(event: any) {}

  onClickDropdownMenu(company: any,menu: Menu, event: any) {
    this.items = [];
    this.items.push({
      label: "Vezi Companie",
      icon: "pi pi-eye",
      command: () => {
        this.router.navigate(["/companie", company.id]);
      },
      
    });
    this.items.push({
      label: "Vezi subscriptie",
      icon: "pi pi-eye",
      command: () => {
        this.router.navigate(["/subscription", company.subscriptionId]);
      },
      
    });
    menu.toggle(event);
  }
}
